<template>
  <div class="block-products-flyout-item">
    <div class="block-products-flyout-item__image">
      <div class="block-products-flyout-item__circle is-white" />
      <div class="block-products-flyout-item__circle is-green" />
      <AtomImage
        v-if="product.image"
        :src="product.image"
      />
    </div>
    <div class="block-products-flyout-item__name">
      {{ product.name }}
    </div>
    <button
      type="button"
      class="block-products-flyout-item__link"
      @click="navigateToProduct()"
    >
      {{ useTranslation('general', 'learn_more') }}
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
    product: {
        type: Object,
        default: () => {},
    },
});

const navigateToProduct = () => {
    navigateTo(`../produkte/${props.product.slug}`);
};
</script>

<style lang="scss">
.block-products-flyout-item {
    display: flex;
    flex-direction: column;

    .block-products-flyout-item__image {
        @include fluid('margin-bottom', 10px, 20px);
        @include fluid('height', 165px, 241px);
        @include fluid('width', 165px, 241px);
        position: relative;
        overflow: hidden;

        padding: 40px;
        border-radius: 12px;
        background: $C_GREEN_BRIGHT;

        .atom-image {
            @include z-index('main');

            position: relative;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .block-products-flyout-item__circle {
            position: absolute;
            bottom: 0;
            width: 200px;
            height: 200px;
            border-radius: 50%;

            &.is-white {
                left: 0;
                background: $C_GREEN_BRIGHT;
                filter: blur(30px);
                opacity: 0.75;
                transform: translate(-10%, 10%);
            }

            &.is-green {
                right: 0;
                background: $C_PRIMARY;
                filter: blur(50px);
                opacity: 0.75;
                transform: translate(30%, 30%);
            }
        }

        @include mobile {
            padding: 25px
        }
    }

    .block-products-flyout-item__name {
        @include typo-font('light');
        @include typo-size('lead');
    }

    .block-products-flyout-item__link {
        @include typo-font('italic');

        color: $C_PRIMARY;

        &:hover {
            color: $C_SECONDARY;
            transition: color 0.25s ease-in-out;
        }
    }
}
</style>
